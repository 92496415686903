.main-popup {
  bottom: 2.5rem;
  right: 1rem;
  z-index: 99;
}

.main-btn {
  height: 25px;
  width: 25px;
}
.notification-icon {
  right: 1.4rem;
}

.allticktes {
  bottom: 2.5rem;
  right: 5.2rem;
  z-index: 99;
}
.ticket-form {
  right: 5.2rem;
  bottom: 2.4rem;
  z-index: 99;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-purple {
  --tw-text-opacity: 1;
  color: rgb(68 94 255 / var(--tw-text-opacity));
}

.w-32 {
  width: 8rem;
}
.w-48 {
  width: 12rem;
}

.w-26 {
  width: 26rem;
}
.comment-box-width {
  width: 25rem;
  height: 58px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  line-height: 58px;
}
::-webkit-input-placeholder {
  /* Webkit */
  line-height: 58px;
}
:-ms-input-placeholder {
  /* IE */
  line-height: 58px;
}
.bg-purple {
  --tw-bg-opacity: 1;
  background-color: rgb(68 94 255 / var(--tw-bg-opacity));
}

.btn-border {
  border: 1px rgb(68 94 255 / var(--tw-bg-opacity)) solid;
}

.w-96 {
  width: 27rem;
}

.w-12 {
  width: 3rem;
}
.w-14 {
  width: 3.5rem;
}

.image-editor {
  width: 80rem;
  height: 50rem;
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  right: 17.2rem;
  bottom: -0.3rem;
}

.rounded-md {
  border-radius: 0.375rem;
}
.cursor-pointer {
  cursor: pointer;
}

.h-80 {
  height: 21.4rem;
}
.h-82 {
  height: 23.5rem;
}
.h-71 {
  height: 13.35rem;
}

.w-82 {
  width: 22.5rem;
}

input,
textarea:focus {
  outline: none;
}

.box-border {
  border: 1px solid #80808085;
}

.background-color {
  --tw-bg-opacity: 1;
  background-color: rgb(236 236 236 / var(--tw-bg-opacity));
}
.text-gray2 {
  --tw-text-opacity: 1;
  color: rgb(96 96 96 / var(--tw-text-opacity));
}
.summary {
  bottom: 2.5rem;
  right: 49.3rem;
}

.hide-scroller::-webkit-scrollbar {
  display: none;
}

.text-xxs {
  font-size: 0.65rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.editor-btn {
  background-color: #d5dadd;
}

.blink_me {
  animation: blinker 0.8s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
